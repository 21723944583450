import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Dropdown } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersList,
  blockUser,
  getUsersListById,
  getBlastListById,
} from "../../../actions/adminAction";
import AdminWrapper from "../adminwrapper/wrapper";
import UserPopup from "../popup/user";
import DeletePopup from "../popup/delete";
import Loader from "../../loader/loader";
import UserTable from "./usertable";
import { useTable } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import dots from "../../../assets/dots.png";

const AdminUser = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const [userData, setUserData] = useState(null);

  const [keyword, setKeyword] = useState("");

  const [page, setPage] = useState(1);
  const [pagination] = useState(10);
  const [listcopy, setListCopy] = useState([]);
  const dispatch = useDispatch();
  const userListData = useSelector((state) => state.userList);
  const { error, userList, loading } = userListData;
  const userDeleteData = useSelector((state) => state.deleteUser);

  const { deleteUserStatus } = userDeleteData;

  const userBlockData = useSelector((state) => state.blockUser);
  const { blockUserStatus } = userBlockData;

  const handleModal = (e, mail) => {
    console.log("e, mail", e, mail);
    setShow(e);
    setEmail(mail);
    // if (mail) {
    //   dispatch(getUsersListById(mail.id));
    //   dispatch(getBlastListById(mail.id));
    // }

    //console.log("hhhhhh", mail.id);
  };

  useEffect(() => {
    if (error) toast.error(error, { id: "A" });
    // dispatch(getUsersList({ page, pagination, keyword }));
    const search = window.location.href;
    const id = search.split("=")[1];
    dispatch(getUsersList(id));

    setShow(true);
  }, [dispatch, error, userDeleteData, userBlockData, keyword]);
  useEffect(() => {
    const arr = [];
    userList &&
      userList.list.forEach((el) => {
        let autoRenew = "";
        let sendReminder = "";
        let memberId = "";
        let membership_type = "";

        if (el)
          if (el.auto_renew === 1) {
            autoRenew = "Yes";
          } else {
            autoRenew = "No";
          }
        if (el.send_reminder === 1) {
          sendReminder = "Yes";
        } else {
          sendReminder = "No";
        }
        if (el.membership_id === "1") {
          memberId = el.member_id;
          membership_type = "Author Premium";
        }
        if (el.membership_id === "2") {
          memberId = el.member_id;
          membership_type = "Reader";
        }
        if (el.membership_id === "3") {
          memberId = el.member_id;
          membership_type = "Blogger";
        }
        if (el.membership_id === "4") {
          memberId = el.member_id;
          membership_type = "Vendor";
        }
        if (el.membership_id === "5") {
          memberId = el.member_id;
          membership_type = "PR Firm";
        }
        if (el.membership_id === "6") {
          memberId = el.member_id;
          membership_type = "Blogger Premium";
        }
        if (el.membership_id === "8") {
          memberId = el.member_id;
          membership_type = "Author";
        }

        if (el.membership_id === "9") {
          memberId = el.member_id;
          membership_type = "Super User";
        }

        arr.push({
          ...el,
          auto_renew: autoRenew,
          send_reminder: sendReminder,
          member_id: memberId,
          membership: membership_type,
          date_started: moment(el.created_date).format("MMM-DD-YYYY"),
          member_expiry_date:
            el.member_expiry_date &&
            moment(el.member_expiry_date).format("MMM-DD-YYYY"),
        });
      });

    console.log("listCopy", arr);
    setListCopy(arr);
  }, [userList]);

  useEffect(() => {
    if (deleteUserStatus) {
      toast.success(deleteUserStatus, { id: "A" });
    }
    if (userDeleteData.error) toast(userDeleteData.error, { id: "A" });
  }, [dispatch, deleteUserStatus, userDeleteData]);

  useEffect(() => {
    if (blockUserStatus) {
      toast.success(blockUserStatus, { id: "A" });
    }
    if (userBlockData.error) toast(userBlockData.error, { id: "A" });
  }, [dispatch, blockUserStatus, userBlockData]);

  const searchUser = () => {
    dispatch(getUsersList({ page, pagination, keyword }));
  };

  const columns = [
    // {
    // Header: "Name",
    // columns: [
    {
      Header: "ID",
      accessor: "id",
    },

    {
      Header: "Username",
      accessor: "display_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: "Last Name",
      accessor: "lname",
    },
    {
      Header: "Profile name",
      accessor: "fname",
      Cell: (cell) => {
        return (
          <span>
            {cell.row.values.fname && cell.row.values.fname}{" "}
            {cell.row.values.lname && cell.row.values.lname}
          </span>
        );
      },
    },
    {
      Header: "Type",
      accessor: "membership",
    },
    {
      Header: "profile",
      accessor: "profile_details_with_values",
    },
    {
      Header: "mid",
      accessor: "membership_id",
    },
    {
      Header: "Start Date",
      accessor: "date_started",
    },
    {
      Header: "End Date ",
      accessor: "member_expiry_date",
    },
    {
      Header: "Auto Renew",
      accessor: "auto_renew",
      Cell: (cell) => {
        return <span className="togglebox">{cell.row.values.auto_renew} </span>;
      },
    },
    {
      Header: "Reminder Frequency",
      accessor: "reminder_frequency",
      // Cell: (cell) => {
      //   return (
      //     <span className="togglebox">
      //       {cell.row.values.reminder_frequency}{" "}
      //     </span>
      //   );
      // },
    },
    {
      Header: "Email Frequency",
      accessor: "emails_frequency",
    },
    {
      Header: "Send Reminder",
      accessor: "send_reminder",
      // Cell: (cell) => {
      //   return (
      //     <span className="togglebox">
      //       {cell.row.values.send_reminder}{" "}
      //     </span>
      //   );date_join
      // },
    },

    {
      Header: "Updated Date",
      accessor: "updated_date",
    },

    {
      Header: "Active",
      accessor: "is_active",
    },
    {
      Header: "Action",
      accessor: "",
      Cell: (cell) => {
        // console.log("cell", cell.row.values.is_active);
        return (
          <div className="action-dropdown action-buttons">
            <Button
              value={cell.row.values.id}
              // className="edit-btn"
              onClick={() => handleModal("user", cell.row.values)}
            >
              View{" "}
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <img src={dots} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item></Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    onClick={() => handleModal("delete", cell.row.values.id)}
                    className="delete-btn"
                  >
                    Permanently delete
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    onClick={() =>
                      dispatch(
                        !cell.isBlocked
                          ? blockUser(cell.row.values.email)
                          : blockUser(cell.row.values.email),
                        ""
                      )
                    }
                    className="block-btn"
                  >
                    {cell.row.values.is_active === 0 ? "Unsuspend" : "Suspend/Cancel"}
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          // <div className="action-buttons">
          //   <Button
          //     value={cell.row.values.id}
          //     className="edit-btn"
          //     onClick={() => handleModal("user", cell.row.values)}
          //   >
          //     View{" "}
          //   </Button>
          //   <Button
          //     onClick={() => handleModal("delete", cell.row.values.id)}
          //     className="delete-btn"
          //   >
          //     Delete
          //   </Button>
          //   <Button
          //     onClick={() =>
          //       dispatch(
          //         !cell.isBlocked
          //           ? blockUser(cell.row.values.email)
          //           : blockUser(cell.row.values.email),
          //         ""
          //       )
          //     }
          //     className="block-btn"
          //   >
          //     {cell.row.values.is_active === 0 ? "Unblock" : "Block"}
          //   </Button>
          // </div>
        );
      },
      //  },
      //     ],
    },
  ];

  //console.log('TTT',userList)
  return (
    <AdminWrapper>
      <div className="admin-user">
        <div className="admin-transaction">
          <div className="container">
            <h2 className="admin-title">Members</h2>
            <div className="admin-tab">
              <div className="user-search-box">
                <div className="user-search-section">
                  <div className="user-search">
                    {/* <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="search"
                  placeholder="Search Here..."
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </Form.Group> */}
                    {/* <Button variant="primary" type="button" onClick={searchUser}>
              <i className="fa fa-search" aria-hidden="true"></i>
              </Button> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {loading ? (
                      <Loader />
                    ) : (
                      userList && (
                        <UserTable
                          columns={columns}
                          data={listcopy && listcopy}
                        />
                      )
                    )}
                    {/* {loading ? (
                <Loader />
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>ID</th>
                      <th>Auto Renew</th>
                      <th>Reminder Frequency</th>
                      <th>Send Reminder</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList && userList.list.length ? (
                      userList.list.map((item, i) => (
                        <tr key={`item-${i}`}>
                          <td>{item.display_name}</td>
                          <td>{item.email}</td>
                          <td>{item.follow_code}</td>
                          <td>
                            <span className="togglebox">
                              {item.auto_renew ? "Yes" : "No"}{" "}
                            </span>
                          </td>

                          <td>
                            <sapn className="togglebox">
                              {item.reminder_frequency}
                            </sapn>
                          </td>
                          <td>
                            <span className="togglebox">
                              {item.send_reminder ? "Yes" : "No"}{" "}
                            </span>
                          </td>
                          <td>
                            <div className="action-buttons">
                              <Button
                                className="edit-btn"
                                onClick={() => handleModal("user", item)}
                              >
                                View{" "}
                              </Button>
                              <Button
                                onClick={() => handleModal("delete", item.id)}
                                className="delete-btn"
                              >
                                Delete
                              </Button>
                              <Button
                                onClick={() =>
                                  dispatch(
                                    !item.isBlocked
                                      ? blockUser(item.email)
                                      : blockUser(item.email),
                                    ""
                                  )
                                }
                                className="block-btn"
                              >
                                {item.is_active ? "Unblock" : "Block"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No User Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )} */}
                    {/* <div className="admin-pagination">
                <Pagination>
                  <Pagination.First onClick={() => setPage(1)} />
                  <Pagination.Prev
                    onClick={() => (page > 1 ? setPage(page - 1) : "")}
                  />
                  <Pagination.Item>{page}</Pagination.Item>
                  <Pagination.Next
                    onClick={() =>
                      page < Math.ceil(userList.total / pagination)
                        ? setPage(page + 1)
                        : ""
                    }
                  />
                  <Pagination.Last
                    onClick={() =>
                      setPage(Math.ceil(userList.total / pagination))
                    }
                  />
                </Pagination>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="login-popup"
        show={show === "delete"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeletePopup handleModal={handleModal} mail={email} type="user" />
        </Modal.Body>
      </Modal>

      <Modal
        className="login-popup"
        show={show === "user"}
        onHide={() => handleModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserPopup forwarded={"userlisting"} user={email} />
        </Modal.Body>
      </Modal>
    </AdminWrapper>
  );
};

export default AdminUser;
